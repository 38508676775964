import React, { FC } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Footer from '../new/components/Footer';
import { CategoriesContextProvider } from '../old/storyblok/categories-context';
import { TagsContextProvider } from '../old/storyblok/tags-context';

const footerConfig = graphql`
  query Footer {
    footerData: allStoryblokEntry(
      filter: { field_component: { eq: "footer" } }
    ) {
      nodes {
        content
        uuid
      }
    }
    tagData: allStoryblokEntry(
      filter: {
        full_slug: { glob: "tags/**" }
        field_component: { eq: "tagCollectionPage" }
      }
    ) {
      nodes {
        full_slug
        content
        uuid
        slug
      }
    }
    categoriesData: allStoryblokEntry(
      filter: {
        full_slug: { glob: "categories/**" }
        field_component: { eq: "categoryCollectionPage" }
      }
    ) {
      nodes {
        full_slug
        content
        uuid
        slug
      }
    }
  }
`;

const FooterWrap: FC = () => {
  const data: GatsbyTypes.FooterQuery = useStaticQuery(footerConfig);

  const footerNode = data?.footerData?.nodes?.[0];

  const footerData =
    footerNode && footerNode.content ? JSON.parse(footerNode.content) : {};

  const parsedTag = data.tagData.nodes.map((tag) => ({
    title: tag && tag.content ? JSON.parse(tag.content).display_name : '',
    uuid: tag.uuid || '',
    full_slug: tag.full_slug || '',
    slug: tag.slug || '',
  }));

  const parsedCategories = data.categoriesData.nodes.map((category) => ({
    title:
      category && category.content
        ? JSON.parse(category.content).display_name
        : '',
    uuid: category.uuid || '',
    full_slug: category.full_slug || '',
    slug: category.slug || '',
  }));

  return (
    <CategoriesContextProvider data={parsedCategories}>
      <TagsContextProvider data={parsedTag}>
        <Footer {...footerData} />
      </TagsContextProvider>
    </CategoriesContextProvider>
  );
};

export default FooterWrap;
