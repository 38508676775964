import React from 'react';
import { createContext, ReactNode, useContext } from 'react';

export type TagType = {
  title: string;
  uuid: string;
  full_slug: string;
  slug: string;
};

export interface TagsContextShape extends Array<TagType> {}

interface TagsContextProviderProps {
  children: ReactNode;
  data: TagType[];
}

const TagsContext = createContext<TagsContextShape | undefined>(undefined);

export const TagsContextProvider = ({
  children,
  data,
}: TagsContextProviderProps): JSX.Element => {
  return <TagsContext.Provider value={data}>{children}</TagsContext.Provider>;
};

export const useTags = () => {
  const tagsContext = useContext(TagsContext);
  if (tagsContext === undefined) {
    throw new Error('useTags must be used within a TagsContextProvider');
  }

  return tagsContext;
};
