import { createGlobalStyle, css } from 'styled-components';
import { resetHeadings } from './typography';
import defaultTheme, { colors, darkTheme } from './themeVariables';
import { breakpointUp } from './mediaUtils';
import { spacing } from './newThemeVariables.styles';
import { typeScale } from './newTypography';

const buttonStyle = css`
  text-transform: uppercase;
  padding: ${spacing.space10} ${spacing.space16};
  text-align: center;
  border: 0.0781rem solid ${({ theme }) => theme.border.Border500};
  border-radius: 6.25rem;

  &:hover {
    border: 0.0781rem solid ${({ theme }) => theme.border.Border400};
  }

  &:active {
    border: 0.0781rem solid ${({ theme }) => theme.border.Border600};
  }

  &:focus {
    border: 0.0781rem solid ${({ theme }) => theme.border.BorderFocus};
  }

  &:disabled {
    border: 0.0781rem solid ${({ theme }) => theme.border.Border500};
    color: ${({ theme }) => theme.text400};
  }
`;

const GlobalStyles = createGlobalStyle`
  /* ${resetHeadings()} */
  html {
    font-family: 'f37_jagger', 'Helvetica', sans-serif;
    font-size: ${defaultTheme.remFontSize};
    line-height: 1.5;
    color: var(--fg, #161314);

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    touch-action: manipulation;
  }

  body {
    margin: 0;
    background-color: ${({ theme }) => theme.bgPrimary};

    /* to prevent random horizontal scrollbar during page transitions */
    overflow-x: hidden;
  }

  input,
  textarea,
  select,
  button {
    font-family: inherit;
    cursor: pointer;
    outline: none;
  }

  a {
    color: inherit;
    text-decoration: none;
    cursor: pointer;
  }

  button:focus,
  a:focus {
    outline: none;
    box-shadow: none;
    border-color: transparent;
  }

  figure {
    margin: 0;
  }

  /* cookie banner stylings */
  .osano-cm-dialog {
    ${typeScale.mobile.body.xs};
    margin: ${spacing.space12};
    padding: ${spacing.space24};
    padding-bottom: ${spacing.space40};
    background: ${darkTheme(defaultTheme).bgPrimary};
    color: ${darkTheme(defaultTheme).textPrimary};
    border-radius: 0.375rem;
    display: flex;
    row-gap: ${spacing.space40};
    border: 0.0625rem solid ${darkTheme(defaultTheme).border000};
    box-shadow: ${({ theme }) => theme.dropShadow};
  }

  .osano-cm-dialog--type_bar {
    flex-direction: column;
    left: auto;
  }

  .osano-cm-storage-policy {
    ${typeScale.desktop.mono.md};
    color: ${darkTheme(defaultTheme).textPrimary} !important;
    text-decoration: none;
    border-bottom: 0.0625rem solid ${
      darkTheme(defaultTheme).border500
    } !important;
    margin-left: 0.25rem;
  }

  .osano-cm-storage-policy::after {
    content: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTMiIGhlaWdodD0iMTMiIHZpZXdCb3g9IjAgMCAxMyAxMyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik00LjYyODg4IDMuMDA5MDZMOS44ODM3MiAyLjk4OTQyTDkuODgzMjQgMy4xMTY5MkwxMC4wMTA3IDMuMTE2NDVMOS45OTExMSA4LjM3MTI4TDguOTQwMTUgOC4zNzUyMUw4Ljk1NjMzIDQuMDQzODRMNC42MjQ5NiA0LjA2MDAyTDQuNjI4ODggMy4wMDkwNlpNMi41OTI5MyA5LjQ4NzIyTDYuMzIyNTUgNS43NTc2MUw3LjA2MjkxIDYuNDk3OThMMy4zMzMzIDEwLjIyNzZMMi41OTI5MyA5LjQ4NzIyWiIgZmlsbD0iI0YwRjBGMCIvPgo8L3N2Zz4K");
    margin-left: 0.5rem;
    display: inline-block;
    position: relative;
    top: 2px;
  }

  .osano-cm-storage-policy:hover::after {
    filter: brightness(0.5);
  }

  .osano-cm-storage-policy:hover {
    color: ${darkTheme(defaultTheme).text400} !important;
  }

  .osano-cm-dialog--type_bar .osano-cm-dialog__buttons {
    flex: 1;
    margin: 0;
    width: 100%;
    max-width: none;
    min-width: auto;
    justify-content: end;
    align-items: center;
    column-gap: 1rem;
    row-gap: 1rem;
}

  .osano-cm-dialog--type_bar .osano-cm-button {
    ${typeScale.desktop.mono.md};
    width: auto;
    flex: none;
    margin: 0;
   ${buttonStyle}
  }
  
  .osano-cm-button--type_accept,
  .osano-cm-button--type_accept:hover  {
    background: ${({ theme }) => theme.bgPrimary};
    color: ${({ theme }) => theme.textPrimary};
  }

  .osano-cm-info {
    ${typeScale.mobile.body.xs};
    background: ${({ theme }) => theme.bgPrimary};
    color: ${({ theme }) => theme.textPrimary};
    max-width: 24rem;
  }

  .osano-cm-info [role="heading"] {
    ${typeScale.mobile.body.md};
  }

  .osano-cm-description {
    ${typeScale.mobile.body.xs};
    color: ${({ theme }) => theme.text300};
  }

  .osano-cm-info [role="link"] {
    ${typeScale.mobile.body.xs};
    color: ${({ theme }) => theme.text400};
    font-weight: 700;
  }

  .osano-cm-cookie-disclosure__title, 
  .osano-cm-script-disclosure__title {
    ${typeScale.mobile.body.xs};
    font-weight: 700;
  }

  .osano-cm-save {
    ${buttonStyle}
    ${typeScale.desktop.body.xs};
    background: ${darkTheme(defaultTheme).bgPrimary};
    color: ${darkTheme(defaultTheme).text200};
    text-transform: none;
    border-radius: 0;
  }

  /* radio switches */
  .osano-cm-toggle__input:checked + .osano-cm-toggle__switch {
    background-color: ${colors.blue} !important;
    border-color: ${colors.blue} !important;
  }

  .osano-cm-toggle__input:checked:focus + .osano-cm-toggle__switch::before {
    border-color: ${colors.blue} !important;
  }

  .osano-cm-link {
    ${typeScale.desktop.body.xxs};
    color: ${({ theme }) => theme.text400};
  }

  @media ${breakpointUp.tablet} {
    .osano-cm-dialog {
      ${typeScale.tablet.body.xs};
    }

    .osano-cm-dialog--type_bar {
      max-width: 23rem;
    }

    .osano-cm-info {
      ${typeScale.tablet.body.xs};
    }

    .osano-cm-info [role="heading"] {
      ${typeScale.tablet.body.md};
    }

    .osano-cm-description {
      ${typeScale.tablet.body.xs};
    }

    .osano-cm-info [role="link"] {
      ${typeScale.tablet.body.xs};
      color: ${({ theme }) => theme.text400};
      font-weight: 700;
    }

    .osano-cm-cookie-disclosure__title, 
    .osano-cm-script-disclosure__title {
      ${typeScale.tablet.body.xs};
      font-weight: 700;
    }
  }

  @media ${breakpointUp.desktop} {
    .osano-cm-dialog {
      ${typeScale.desktop.body.xs};
      row-gap: ${spacing.space48};
    }

    .osano-cm-dialog--type_bar .osano-cm-dialog__content::before {
      ${typeScale.desktop.body.md};
      content: '🍪 Cookie policy';
      display: block;
      margin-bottom: ${spacing.space16};
    }

    .osano-cm-storage-policy {
      margin-left: 0;
      margin-top: 1rem;
    }

    .osano-cm-info {
      ${typeScale.desktop.body.xs};
    }

    .osano-cm-info [role="heading"] {
      ${typeScale.desktop.body.md};
    }

    .osano-cm-description {
      ${typeScale.desktop.body.xs};
    }

    .osano-cm-info [role="link"] {
      ${typeScale.desktop.body.xs};
      color: ${({ theme }) => theme.text400};
      font-weight: 700;
    }

    .osano-cm-cookie-disclosure__title, 
    .osano-cm-script-disclosure__title {
      ${typeScale.desktop.body.xs};
      font-weight: 700;
    }
  }

  .osano-cm-widget {
    display: none;
  }

  /* hide deny button in cookie dialog banner: */
  .osano-cm-button--type_denyAll {
    display: none;
  }

  .osano-cm-close {
    display: none;
  }

  /* .osano-cm-message {
    line-height: 1.75rem;
    letter-spacing: 0.02rem;
  } */

  /* stylelint-disable-next-line selector-list-comma-newline-after */
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 0;
    font-weight: normal;
    touch-action: manipulation;
  }

  p {
    margin-top: 0;
  }

  /* cms codeblock styling */
  code {
    border: 1px solid #dadce0;
    border-radius: 3px;
    padding: 0.5rem;
    background-color: ${colors.snow};
  }

  /* Gatsby Image Placeholder Fade Fix */
  /* https://github.com/gatsbyjs/gatsby/issues/20126#issuecomment-687935525 */
  .gatsby-image-wrapper img,
  .gatsby-image-wrapper > div {
    transition: opacity 500ms ease 0ms !important;
  }
`;

export default GlobalStyles;
