import React, { FC, useMemo } from 'react';
import { ThemeProvider } from 'styled-components';
import {
  MainTitle,
  Upper,
  Nav,
  SectionTitle,
  SectionList,
  SectionListItem,
  Lower,
  Legal,
  Cookies,
  Categories,
  CategoryTitle,
  CategoryList,
  CategoryListItem,
  Copyright,
} from './Footer.styles';
import NavLink, { NavLinkProps } from './NavLink';
import defaultTheme, { darkTheme } from '../../../styles/themeVariables';
import { useTags, TagsContextShape } from '../../../old/storyblok/tags-context';
import { useCategories } from '../../../old/storyblok/categories-context';
import { StoryblokLinkObject } from '../../../old/storyblok/util/resolveInternalLink';
import { Section } from '../../layout/Section';
import { Layout } from '../../layout/Layout';
import { Grid } from '../../layout/Grid';

/**
 * Open cookie drawer
 * to change cookie and privacy preferences
 * @returns void
 */
const openCookieDrawer = (event: React.MouseEvent<HTMLButtonElement>) => {
  event.preventDefault();
  window.Osano.cm.showDrawer('osano-cm-dom-info-dialog-open');
};

/**
 * Exclude 'Engineering Tips' tag from list
 * @returns array of filtered tags
 */
const filterTags = (tags: TagsContextShape) => {
  const TAGS_TO_EXCLUDE = ['Engineering Tips'];
  return tags.filter((tag) => !TAGS_TO_EXCLUDE.includes(tag.title));
};

export interface FooterProps {
  lightBg?: boolean;
  mainTitle: string;
  contactTitle: string;
  phoneNumber: string;
  email: string;
  address: string;
  primaryLinksTitle: string;
  primaryLinks: NavLinkProps[];
  secondaryLinksTitle: string;
  secondaryLinks: NavLinkProps[];
  socialLinksTitle: string;
  socialLinks: NavLinkProps[];
  cookieTitle: string;
  tagTitle: string;
  categoryTitle: string;
  companyName: string;
  slaveryStatementTitle: string;
  slaveryStatementLink: StoryblokLinkObject;
}

const Footer: FC<FooterProps> = ({
  lightBg,
  mainTitle,
  contactTitle,
  phoneNumber,
  email,
  address,
  primaryLinksTitle,
  primaryLinks = [],
  secondaryLinksTitle,
  secondaryLinks = [],
  socialLinksTitle,
  socialLinks = [],
  cookieTitle,
  tagTitle,
  categoryTitle,
  companyName,
  slaveryStatementTitle,
  slaveryStatementLink,
}) => {
  const categories = useCategories();
  const tags = useTags();
  const year = useMemo(() => new Date().getFullYear(), []);
  const filteredTags = useMemo(() => filterTags(tags), [tags]);
  const linkLists = [
    { title: primaryLinksTitle, linkList: primaryLinks },
    { title: secondaryLinksTitle, linkList: secondaryLinks },
    { title: socialLinksTitle, linkList: socialLinks },
  ];

  return (
    <ThemeProvider theme={lightBg ? defaultTheme : darkTheme}>
      <Section as="footer">
        <Layout>
          <Grid>
            <MainTitle>{mainTitle}</MainTitle>
            <Upper>
              <Nav>
                <SectionTitle>{contactTitle}</SectionTitle>
                <SectionList>
                  <SectionListItem>
                    <a href={`tel:${phoneNumber}`}>{phoneNumber}</a>
                  </SectionListItem>
                  <SectionListItem>
                    <a href={`mailto:${email}`}>{email}</a>
                  </SectionListItem>
                  <SectionListItem>{address}</SectionListItem>
                </SectionList>
              </Nav>

              {linkLists.map(
                ({ title, linkList }, index) =>
                  linkList.length > 0 && (
                    <Nav key={index}>
                      <SectionTitle>{title}</SectionTitle>
                      <SectionList>
                        {linkList.map((link) => (
                          <SectionListItem key={link._uid}>
                            <NavLink {...link} />
                          </SectionListItem>
                        ))}
                      </SectionList>
                    </Nav>
                  )
              )}

              {filteredTags.length > 0 && (
                <Nav>
                  <SectionTitle>{tagTitle}</SectionTitle>
                  <SectionList>
                    {filteredTags.map(({ uuid, slug, title }) => (
                      <SectionListItem key={uuid}>
                        <NavLink
                          _uid={uuid}
                          link={{ url: `tag/${slug}/` }}
                          name={title}
                          filterUrl={false}
                        />
                      </SectionListItem>
                    ))}
                  </SectionList>
                </Nav>
              )}
            </Upper>
            <Lower>
              <Legal>
                <NavLink
                  _uid={slaveryStatementLink?.id}
                  link={slaveryStatementLink}
                  name={slaveryStatementTitle}
                />
                <Cookies onClick={openCookieDrawer}>{cookieTitle}</Cookies>
              </Legal>

              {categories.length > 0 && (
                <Categories>
                  <CategoryTitle>{categoryTitle}</CategoryTitle>
                  <CategoryList>
                    {categories.map(({ uuid, slug, title }) => (
                      <CategoryListItem key={uuid}>
                        <NavLink
                          _uid={uuid}
                          link={{ url: `${slug}/about/` }}
                          name={title}
                          filterUrl={false}
                        />
                      </CategoryListItem>
                    ))}
                  </CategoryList>
                </Categories>
              )}

              <Copyright>
                &copy;{year} {companyName}
              </Copyright>
            </Lower>
          </Grid>
        </Layout>
      </Section>
    </ThemeProvider>
  );
};

export default Footer;
