import React, { FC } from 'react';
import * as Dialog from '@radix-ui/react-dialog';
import {
  Content,
  ContentWrapper,
  Overlay,
  StyledMainList,
  StyledCTAButton,
  StyledBurger,
  StyledClose,
} from './NewMobileMenu.styles';
import {
  WORK_PAGE_ROUTE,
  SERVICES_PAGE_ROUTE,
  ABOUT_PAGE_ROUTE,
  BLOG_PAGE_ROUTE,
  REPORTS_PAGE_ROUTE,
  CONTACT_PAGE_ROUTE,
} from '../../../../routes';
import FadeInMenuItem from './FadeInMenuItems';
import Link from 'gatsby-link';
import { WindowLocation } from '@gatsbyjs/reach-router';
import { Layout } from '../../../layout/Layout';
import { Grid } from '../../../layout/Grid';
import { ReactComponent as SvgBurgerMenu } from '../../../../../assets/icons/menu.svg?as=component';
import { ReactComponent as SvgClose } from '../../../../../assets/icons/close.svg?as=component';

interface MenuProps {
  appear?: boolean;
  className?: string;
  location: WindowLocation;
  menuOpen: boolean;
  onOpenChange: (open: boolean) => void;
}

export const menuItems = [
  { path: WORK_PAGE_ROUTE, label: 'Case Studies', delay: 1 },
  { path: SERVICES_PAGE_ROUTE, label: 'Services', delay: 2 },
  { path: ABOUT_PAGE_ROUTE, label: 'About', delay: 3 },
  { path: BLOG_PAGE_ROUTE, label: 'Blog', delay: 4 },
  { path: REPORTS_PAGE_ROUTE, label: 'Reports', delay: 3 },
];

export const NewMobileMenu: FC<MenuProps> = ({
  appear = true,
  menuOpen,
  location,
  onOpenChange,
}) => {
  return (
    <Dialog.Root open={menuOpen} onOpenChange={onOpenChange}>
      {!menuOpen && (
        <Dialog.Trigger asChild>
          <StyledBurger title="open menu">
            <SvgBurgerMenu />
          </StyledBurger>
        </Dialog.Trigger>
      )}
      <Dialog.Portal>
        <Overlay />
        <Content>
          {menuOpen && (
            <Dialog.Close asChild>
              <StyledClose title="close menu">
                <SvgClose />
              </StyledClose>
            </Dialog.Close>
          )}
          <Layout>
            <Grid>
              <ContentWrapper>
                <StyledMainList>
                  {menuItems.map((item) => (
                    <li key={item.label}>
                      <FadeInMenuItem
                        appear={appear}
                        delay={item.delay}
                        element="li"
                      >
                        <Link
                          to={item.path}
                          partiallyActive
                          onClick={() => onOpenChange(false)}
                        >
                          {item.label}
                        </Link>
                      </FadeInMenuItem>
                    </li>
                  ))}
                </StyledMainList>
                <StyledCTAButton
                  to={CONTACT_PAGE_ROUTE}
                  isSelected={location?.pathname === CONTACT_PAGE_ROUTE}
                  size={'md'}
                  onClick={() => onOpenChange(false)}
                >
                  Contact
                </StyledCTAButton>
              </ContentWrapper>
            </Grid>
          </Layout>
        </Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

export default NewMobileMenu;
