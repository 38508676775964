import { css, FlattenSimpleInterpolation } from 'styled-components';

type Breakpoint = 'desktop' | 'tablet' | 'mobile';
type FontType = 'body' | 'mono';
type TextSizes =
  | 'xxs'
  | 'xs'
  | 'sm'
  | 'md'
  | 'lg'
  | 'xl'
  | 'xxl'
  | 'xxxl'
  | 'xxxxl'
  | 'fivexl'
  | 'sixxl';

type Typescale = Record<
  Breakpoint,
  Record<FontType, Partial<Record<TextSizes, FlattenSimpleInterpolation>>>
>;

const bodyBase = css`
  font-family: 'f37_jagger', 'Helvetica', sans-serif;
  text-transform: none;
  font-style: normal;
  font-weight: 400;
`;

const monoBase = css`
  font-family: 'IBM Plex Mono', monospace;
  text-transform: uppercase;
  font-style: normal;
  font-weight: 500;
`;

export const typeScale: Typescale = {
  desktop: {
    body: {
      xs: css`
        ${bodyBase}
        font-size: 0.9375rem;
        line-height: 1.5rem;
      `,
      sm: css`
        ${bodyBase}
        font-size: 1.0625rem;
        line-height: 1.4875rem;
      `,
      md: css`
        ${bodyBase}
        font-size: 1.1875rem;
        line-height: 1.60313rem;
      `,
      lg: css`
        ${bodyBase}
        font-size: 1.375rem;
        line-height: 1.85625rem;
        letter-spacing: -0.01375rem;
      `,
      xl: css`
        ${bodyBase}
        font-size: 1.6875rem;
        line-height: 2.025rem;
        letter-spacing: -0.03375rem;
      `,
      xxl: css`
        ${bodyBase}
        font-size: 1.9375rem;
        line-height: 2.325rem;
        letter-spacing: -0.01938rem;
      `,
      xxxl: css`
        ${bodyBase}
        font-size: 2.1875rem;
        line-height: 2.625rem;
        letter-spacing: -0.02188rem;
      `,
      xxxxl: css`
        ${bodyBase}
        font-size: 2.875rem;
        line-height: 3.45rem;
        letter-spacing: -0.0575rem;
      `,
      fivexl: css`
        ${bodyBase}
        font-size: 3.5rem;
        line-height: 4.2rem;
        letter-spacing: -0.07rem;
      `,
      sixxl: css`
        ${bodyBase}
        font-size: 4.875rem;
        line-height: 5.85rem;
        letter-spacing: -0.0975rem;
      `,
    },
    mono: {
      md: css`
        ${monoBase}
        font-size: 0.6875rem;
        line-height: 1.0725rem;
        letter-spacing: 0.0825rem;
      `,
      sm: css`
        ${monoBase}
        font-weight: 600;
        font-size: 0.625rem;
        line-height: 1.0625rem;
        letter-spacing: 0.09375rem;
      `,
    },
  },
  tablet: {
    body: {
      xs: css`
        ${bodyBase}
        font-size: 0.875rem;
        line-height: 1.3125rem;
      `,
      sm: css`
        ${bodyBase}
        font-size: 1rem;
        line-height: 1.4rem;
      `,
      md: css`
        ${bodyBase}
        font-size: 1.125rem;
        line-height: 1.63125rem;
      `,
      lg: css`
        ${bodyBase}
        font-size: 1.25rem;
        line-height: 1.625rem;
      `,
      xl: css`
        ${bodyBase}
        font-size: 1.5rem;
        line-height: 1.95rem;
      `,
      xxl: css`
        ${bodyBase}
        font-size: 1.6875rem;
        line-height: 2.025rem;
      `,
      xxxl: css`
        ${bodyBase}
        font-size: 1.9375rem;
        line-height: 2.325rem;
      `,
      xxxxl: css`
        ${bodyBase}
        font-size: 2.5rem;
        line-height: 2.6rem;
      `,
      fivexl: css`
        ${bodyBase}
        font-size: 3.5rem;
        line-height: 4.2rem;
        letter-spacing: -0.07rem;
      `,
      sixxl: css`
        ${bodyBase}
        font-size: 3.0625rem;
        line-height: 3.675rem;
        letter-spacing: -0.06125rem;
      `,
    },
    mono: {
      md: css`
        ${monoBase}
        font-size: 0.75rem;
        line-height: 1.125rem;
        letter-spacing: 0.0825rem;
      `,
      sm: css`
        ${monoBase}
        font-weight: 500;
        font-size: 0.625rem;
        line-height: 1.0625rem;
        letter-spacing: 0.09375rem;
      `,
    },
  },
  mobile: {
    body: {
      xs: css`
        ${bodyBase}
        font-size: 1rem;
        line-height: 1.4rem;
      `,
      sm: css`
        ${bodyBase}
        font-size: 1.0625rem;
        line-height: 1.43438rem;
      `,
      md: css`
        ${bodyBase}
        font-size: 1.1875rem;
        line-height: 1.6625rem;
      `,
      lg: css`
        ${bodyBase}
        font-size: 1.3125rem;
        line-height: 1.64063rem;
      `,
      xl: css`
        ${bodyBase}
        font-size: 1.4375rem;
        line-height: 1.79688rem;
      `,
      xxl: css`
        ${bodyBase}
        font-size: 1.5625rem;
        line-height: 1.875rem;
      `,
      xxxl: css`
        ${bodyBase}
        font-size: 1.6875rem;
        line-height: 2.025rem;
      `,
      xxxxl: css`
        ${bodyBase}
        font-size: 1.8125rem;
        line-height: 1.99375rem;
      `,
      fivexl: css`
        ${bodyBase}
        font-size: 3.5rem;
        line-height: 4.2rem;
        letter-spacing: -0.07rem;
      `,
      sixxl: css`
        ${bodyBase}
        font-size: 4.0625rem;
        line-height: 4.875rem;
        letter-spacing: -0.08125rem;
      `,
    },
    mono: {
      md: css`
        ${monoBase}
        font-size: 0.75rem;
        line-height: 1.5rem;
        letter-spacing: 0.105rem;
      `,
      sm: css`
        ${monoBase}
        font-weight: 500;
        font-size: 0.625rem;
        line-height: 1.0625rem;
        letter-spacing: 0.09375rem;
      `,
    },
  },
};
