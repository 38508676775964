import { WindowLocation } from '@gatsbyjs/reach-router';
import NavThemeSetter from './NavThemeSetter';
import Nav from './NavComponent';
import React, { FC, useEffect, useState } from 'react';
import themeVariables from '../../../styles/themeVariables';

type Props = {
  location: WindowLocation;
};

const createThrottle = () => {
  let ticking = false;

  return (doFunction: () => void) => {
    // scroll event throttling as per MDN example:
    // https://developer.mozilla.org/en-US/docs/Web/API/Document/scroll_event
    if (!ticking) {
      window.requestAnimationFrame(() => {
        doFunction();
        ticking = false;
      });
      ticking = true;
    }
  };
};

const scrollThrottle = createThrottle();
const resizeThrottle = createThrottle();

const NavContainer: FC<Props> = ({ location }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [scrolledUp, setScrolledUp] = useState(false);
  const [scrollTop, setScrollTop] = useState(0);

  const onToggleMenu = (): void => {
    setMenuOpen((prevMenuOpen) => !prevMenuOpen);
  };

  const handleScroll = (): void => {
    scrollThrottle(() => {
      const newScrollTop = window.scrollY;
      const isScrolledUp = newScrollTop < scrollTop;

      setScrolledUp(isScrolledUp);
      setScrollTop(newScrollTop);
    });
  };

  const handleResize = (): void => {
    resizeThrottle(() => {
      if (
        window.screen.width >=
        parseInt(themeVariables.breakpoints.md.replace('px', ''), 10)
      ) {
        setMenuOpen(false);
      }
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleResize);
    };
  });

  return (
    <NavThemeSetter location={location}>
      <Nav
        menuOpen={menuOpen}
        toggleMenu={onToggleMenu}
        scrolledUp={scrolledUp}
        top={scrollTop <= 0 || menuOpen}
        location={location || { pathname: '' }}
      />
    </NavThemeSetter>
  );
};

export default NavContainer;
